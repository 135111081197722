/* http://meyerweb.com/eric/tools/css/reset/
   v4.0 | 20180602
   License: none (public domain)
*/
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,main,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}
article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section{display:block}
[hidden]{display:none}
body{line-height:1}
ol,ul{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}
table{border-collapse:collapse;border-spacing:0}



@font-face{
  font-family: 'Futura';
  src: url("./assets/fonts/FuturaStd-Medium.otf") format("opentype");
}

@font-face{
  font-family: 'Futura Light';
  src: url("./assets/fonts/FuturaStd-Light.otf") format("opentype");
}

@font-face{
  font-family: 'Futura Bold';
  src: url("./assets/fonts/FuturaStd-Bold.otf") format("opentype");
}

@font-face{
  font-family: 'Futura ExtraBold';
  src: url("./assets/fonts/FuturaStd-ExtraBold.otf") format("opentype");
}

@font-face{
  font-family: 'Futura Light Italic';
  src: url("./assets/fonts/FuturaStd-LightOblique.otf") format("opentype");
}

@font-face{
  font-family: 'Futura Italic';
  src: url("./assets/fonts/FuturaStd-MediumOblique.otf") format("opentype");
}

@font-face{
  font-family: 'Futura Bold Italic';
  src: url("./assets/fonts/FuturaStd-BoldOblique.otf") format("opentype");
}

@font-face{
  font-family: 'Futura ExtraBold Italic';
  src: url("./assets/fonts/FuturaStd-ExtraBoldOblique.otf") format("opentype");
}



* {
	box-sizing: border-box;
}

body {
  font-family: "Futura";
	line-height: 110%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

a {
  color: inherit;
}

button {
  border: 0px none;
}
button:focus {
  outline: 0px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

input[type="checkbox"] {
  background-color: #ff7d12;
}
input:focus,
select:focus,
textarea:focus {
  outline: 2px auto #ff7d12;
}

.rip-mode-cedeo input[type="checkbox"] {
  background-color: #294178;
}
.rip-mode-cedeo h1 {
  color: #294178;
}
.rip-mode-cedeo input:focus,
.rip-mode-cedeo select:focus,
.rip-mode-cedeo textarea:focus {
  outline: 2px auto #294178;
}

.rip-mode-rexel input[type="checkbox"] {
  background-color: #2E458B;
}
.rip-mode-rexel h1 {
  color: #2E458B;
}
.rip-mode-rexel input:focus,
.rip-mode-rexel select:focus,
.rip-mode-rexel textarea:focus {
  outline: 2px auto #2E458B;
}

input::placeholder,
textarea::placeholder {
  color: #bbbbbb;
  font-style: italic;
}

label {
  cursor: pointer;
}